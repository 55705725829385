import { gql } from '@apollo/client';

export const fetchCommitteeInvitationsQuery = gql`
  query getCommitteeInvitations {
    result: getCommitteeInvitations {
      cmt_name
      cmt_asn_code
      cpo_code
      nms_code
      nom_key
      nom_cst_key
      nom_nms_key
      nom_start_date
      nom_end_date
      org_name
      rc5_start_date
      rc5_end_date
      rc5_location_city_state
    }
  }
`;
