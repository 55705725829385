import { useQuery } from '@apollo/client';

import {
  GetCommitteeInvitationsQuery,
  GetCommitteeInvitationsQueryVariables,
} from '@typings/operations';
import { fetchCommitteeInvitationsQuery } from '../queries/fetchCommitteeInvitations';

export const useFetchCommitteeInvitations = () =>
  useQuery<GetCommitteeInvitationsQuery, GetCommitteeInvitationsQueryVariables>(
    fetchCommitteeInvitationsQuery
  );
