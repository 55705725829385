import * as React from 'react';

import Title from '../../Title';

import { useTranslation } from '@external/react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('default-error-page.404.title', 'Page not found')}</Title>
      <div>
        {t(
          'default-error-page.404.description',
          'The requested page has not been found'
        )}
      </div>
    </>
  );
};

export default NotFound;
