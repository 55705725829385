import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';

import RequireLogin from '@components/Auth/RequireLogin';
import { OneColumn } from '@components/Layouts/OneColumn';
import Title from '@components/Title';

import { FEATURE_SHAREPOINT, isEnabled } from '@utils/features';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import DisplayInvitations from '@presenters/web/pages/CommitteeNominations/DisplayInvitations/displayInvitations';
import NotFound from '@components/Pages/NotFound';
import AuthenticatedRoute from '@components/Auth/AuthenticatedRoute';

const Invitations: React.FC<RouteComponentProps & {
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix } }) => {
  const { t } = useTranslation();
  const isSharePointFeatureEnabled = isEnabled(FEATURE_SHAREPOINT);

  if (!isSharePointFeatureEnabled) {
    return <NotFound />;
  }

  return (
    <OneColumn className="mb-20">
      <div id="my-invitations-page">
        <Helmet
          titleTemplate={`${t(
            'invitations.my-invitations.title',
            'My Invitations'
          )} | ${t('metadata.title.default', 'My Rotary')}`}
        />
        <div className="mb-5">
          <Title>
            {t('invitations.my-invitations.title', 'My Invitations')}
          </Title>
        </div>
        <RequireLogin>
          <Router basepath={`/${languagePrefix}invitations`}>
            <AuthenticatedRoute Component={DisplayInvitations} default />
          </Router>
        </RequireLogin>
      </div>
    </OneColumn>
  );
};

export default Invitations;
