import React from 'react';

import moment from 'moment';

import { ResultsList, ResultsListItem } from '@components/ResultsList';

import { useTranslation } from '@external/react-i18next';
import { ScreenSizes, useScreenSizeLayout } from '@hooks/useScreenSizeLayout';
import { CommitteeNominations } from '@typings/committeeNominations';

interface InvitationsListProps {
  committeeNominationsData: CommitteeNominations[];
  isPresRepCommittee?: boolean;
  respondActionButton?: (invitations: CommitteeNominations) => {};
  isActionsButtonVisible?: boolean;
  isViewColumnVisible?: boolean;
}
const InvitationsList: React.FC<InvitationsListProps> = ({
  committeeNominationsData,
  isPresRepCommittee = true,
  respondActionButton,
  isActionsButtonVisible = true,
  isViewColumnVisible = true,
}) => {
  const { t } = useTranslation();
  const committeeInvitationsTitle = isPresRepCommittee
    ? t('invitations.start-date', 'Start Date')
    : t('invitations.committee-position-serve-as', 'Recommended to serve as');

  const isDesktopSmall = useScreenSizeLayout(ScreenSizes.desktopS);

  const flexClassFn = (
    isActionsButtonVisible: boolean,
    isViewColumnVisible: boolean,
    isDesktopSmall: boolean
  ) => {
    if (isDesktopSmall && !isActionsButtonVisible) {
      return 'flex-1';
    }
    if (isActionsButtonVisible && !isViewColumnVisible) {
      return 'flex-3';
    }
    if (isActionsButtonVisible) {
      return 'desktop-l:flex-3 flex-2 desktop-m:flex-3';
    }
    return 'flex-2';
  };
  return (
    <ResultsList className="mt-1">
      <ResultsListItem
        spacingY={4}
        borderBottomClass=""
        className="bg-gray-100 table-header"
      >
        <div className="flex-wrap items-center flex-1 flex font-bold text-small text-gray-400">
          <div
            className={`flex flex-col self-center ${flexClassFn(
              isActionsButtonVisible,
              isViewColumnVisible,
              isDesktopSmall
            )} text-xs leading-6 w-16 ml-4 mr-4`}
          >
            {isPresRepCommittee
              ? t('invitations.district-conference', 'District Conference')
              : t('invitations.committee', 'Committee')}
          </div>
          <div
            className={`flex flex-col self-center ${
              !isViewColumnVisible ? 'flex-3' : 'flex-2'
            } text-xs leading-6 w-16 ml-10 mr-4`}
          >
            {isPresRepCommittee
              ? t(
                  'invitations.district-conference-location',
                  'City, State/Province'
                )
              : ''}
          </div>
          {isViewColumnVisible && (
            <div
              className={`flex flex-col self-center desktop-custom-delegation1:flex-2 ${flexClassFn(
                isActionsButtonVisible,
                isViewColumnVisible,
                false
              )} text-xs leading-6 w-16 mr-4`}
            >
              {committeeInvitationsTitle}
            </div>
          )}
          {!isViewColumnVisible && (
            <div className="flex flex-col self-center flex-2 desktop-custom-delegation1:flex-1 text-xs leading-6 w-16 mr-4">
              {' '}
            </div>
          )}
          <div
            className={`flex flex-col self-center flex-1 ${
              isActionsButtonVisible ? 'tracking-widest ' : ''
            } text-xs leading-6 w-16 mr-4`}
          >
            {t('invitations.status', 'Status')}
          </div>
          {isActionsButtonVisible && (
            <div className="flex-3 desktop-l:flex-2 desktop-custom-delegation1:flex-2">
              {' '}
            </div>
          )}
        </div>
      </ResultsListItem>
      {committeeNominationsData?.map((invitation, index) => {
        const uniqueIdentifier = `${invitation?.nom_key}-${index}`;

        return (
          <ResultsListItem
            spacingY={4}
            key={uniqueIdentifier}
            className="table-header"
          >
            <div className="hidden tablet:flex flex-1 items-center text-xs leading-xs">
              <div
                className={`flex flex-col self-center ${flexClassFn(
                  isActionsButtonVisible,
                  isViewColumnVisible,
                  isDesktopSmall
                )} text-xs leading-6 w-16 ml-4 mr-4`}
              >
                {isPresRepCommittee
                  ? invitation?.org_name
                  : invitation?.cmt_name}
              </div>
              <div
                className={`flex flex-col self-center ${
                  !isViewColumnVisible ? 'flex-3' : 'flex-2'
                } text-xs leading-6 w-16 ml-10 mr-4`}
              >
                {isPresRepCommittee ? invitation?.rc5_location_city_state : ''}
              </div>
              {isViewColumnVisible && (
                <div
                  className={`flex flex-col self-center desktop-custom-delegation1:flex-2 ${flexClassFn(
                    isActionsButtonVisible,
                    isViewColumnVisible,
                    false
                  )} text-xs leading-6 w-16 mr-4`}
                >
                  {isPresRepCommittee
                    ? moment(new Date(invitation?.rc5_start_date || '')).format(
                        'DD MMM YYYY'
                      )
                    : invitation?.cpo_code}
                </div>
              )}
              {!isViewColumnVisible && (
                <div className="flex flex-col self-center flex-2 desktop-custom-delegation1:flex-1 text-xs leading-6 w-16 mr-4">
                  {' '}
                </div>
              )}
              <div
                className={`flex flex-col self-center flex-1 ${
                  isActionsButtonVisible
                    ? 'desktop-custom-delegation2:tracking-widest desktop-custom-delegation3:tracking-widest'
                    : ''
                }  text-xs leading-6 w-16 mr-4`}
              >
                {invitation?.nms_code}
              </div>
              {isActionsButtonVisible && (
                <div className="flex-3 desktop-l:flex-2 desktop-custom-delegation1:flex-2">
                  {' '}
                  {respondActionButton?.(invitation)}
                </div>
              )}
            </div>
          </ResultsListItem>
        );
      })}
    </ResultsList>
  );
};
export default InvitationsList;
